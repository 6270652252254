import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div style={{
            backgroundImage: `url("/public/img/g8_login_dark.svg")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundColor: 'rgb(17 50 103)',
            backgroundBlendMode: 'lighten',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            padding: '50px'
        }}>
            <div style={{
                backgroundColor: 'rgb(24, 27, 31)',
                padding: '20px',
                textAlign: 'center',
                borderRadius: '0px',
                maxWidth: '25%',
                maxHeight: '100%',
                overflow: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgb(204, 204, 220) transparent',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar-track': {
                    background: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgb(204, 204, 220)',
                    borderRadius: '20px',
                    border: '3px solid transparent'
                }
            }}>
                <div style={{ position: 'relative' }}>
                    <img src="/public/img/nois_logo_text_dark.png" alt="Logo" style={{ width: '200px', height: 'auto', marginBottom: '60px' }} />
                    <div style={{
                        position: 'absolute',
                        bottom: '-5px',
                        left: '0',
                        height: '1px',
                        width: '100%',
                        backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgb(145, 145, 145), rgba(255, 255, 255, 0))',
                        marginBottom: '40px'
                    }} />
                </div>
                <h1 style={{ fontWeight: 'bold' }}>PRIVACY NOTICE</h1>
                <p>This privacy notice for Block Communications, describes how and why we might collect, store, use, and/or share your information when you use our services, such as when you:</p>
                <ul style={{ marginBottom: '20px', listStyleType: 'none' }}>
                    <li>Visit our website at application suite, including NOIS.</li>
                </ul>
                <p>We reserve the right to change this policy at any time and will list these changes in the updates section of the policy. By reading this notice and visiting the site, you agree that you understand that customers will not be personally notified when this policy changes.</p>
                <p>Therefore, we advise our customers to frequently review our privacy policy so that they remain aware of its updates. By using the site, you accept that the posted policy and all its changes apply to your interaction with Block Communications.</p>
                <h2 style={{ fontWeight: 'bold' }}>Information Collected by Block Communications</h2>
                <p>Personal information may be collected by this site in many ways. This information includes:</p>
                <ol style={{ marginBottom: '20px', listStyleType: 'none' }}>
                    <li>Personal identifying information like your name, address, email, phone number and other business data</li>
                    <li>Server data related to the IP address you used to visit our NOIS Suite Services, which includes your address, browser, OS, access time, and site activity.</li>
                </ol>
                <h2 style={{ fontWeight: 'bold' }}>How We Use This Information</h2>
                <p>Our Services may use information collected to:</p>
                <ul style={{ marginBottom: '20px', listStyleType: 'none' }}>
                    <li>Manage your account information</li>
                    <li>Email your account confirmation</li>
                    <li>Increase site efficiency</li>
                    <li>Notify you of updates</li>
                    <li>Offer new products</li>
                    <li>Monitor and prevent data theft</li>
                    <li>Request your customer feedback</li>
                    <li>Respond to your service requests</li>
                </ul>
                <h2 style={{ fontWeight: 'bold' }}>Information Disclosure</h2>
                <p>Normally, your information stays on our site. However, below we have listed the situations that may require us to share the information we collect from you:</p>
                <ul style={{ marginBottom: '20px', listStyleType: 'none' }}>
                    <li>When required by law, such as for fraud protection</li>
                    <li>With your consent for marketing purposes</li>
                    <li>If data must be transferred</li>
                </ul>
                <h2 style={{ fontWeight: 'bold' }}>Cookies, Trackers, and Online Ads</h2>
                <p>We may use cookies, trackers, web beacons, and other technology to customize our Services to improve your experience. We may customize the site using this information. These trackers do not have access to your personal information and can be removed from your browser options.</p>
                <h2 style={{ fontWeight: 'bold' }}>Other Sites</h2>
                <p>Once you leave our site, we are no longer responsible for how your information is collected and disclosed. Please refer to the privacy policies of those third-party sites for more information.</p>
                <h2 style={{ fontWeight: 'bold' }}>Information Security</h2>
                <p>We take technical and administrative precautions to protect your data, but we cannot guarantee its safety against all types of fraud or misuse. If you provide personal information, we cannot verify its total security against all types of interception.</p>
                <h2 style={{ fontWeight: 'bold' }}>Do-Not-Track</h2>
                <p>Some browsers offer Do-Not-Track settings to prevent any information from being distributed. Since these settings have not been legally established as standard practice, we do not acknowledge these settings.</p>
                <h2 style={{ fontWeight: 'bold' }}>Additional Options</h2>
                <p>At any time, you may opt to review or change your account settings, including contact information. If you wish to delete your account, you may do so to remove most of your information, however, some identifying information will be retained to prevent fraud.</p>
                <p>You may also opt-out of emails and other correspondence from our site at any time.</p>
                <h2 style={{ fontWeight: 'bold' }}>Privacy Rights</h2>
                <p>The Company may share the above information with our employees or representatives with a “need-to-know” in the performance of their official duties, other Federal agencies, or other named representatives as needed to quickly process your request or transaction. This information is only used to help us make our site more useful for you. Raw data logs are retained temporarily as required for security and site management purposes only.</p>
                <h2 style={{ fontWeight: 'bold' }}>Contact Us</h2>
                <p>If you have questions or concerns about this privacy policy, please feel free to contact us at:</p>
                <a href="https://telesystem.billcenter.net/" target="_blank" style={{ color: 'inherit', textDecoration: 'none' }}>Support</a>
            </div>
        </div>
    );
}

export default PrivacyPolicy;