import React from 'react';

const TermsOfService: React.FC = () => {
    return (
        <div style={{
            backgroundImage: `url("/public/img/g8_login_dark.svg")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundColor: 'rgb(17 50 103)',
            backgroundBlendMode: 'lighten',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            padding: '50px'
        }}>
            <div style={{
                backgroundColor: 'rgb(24, 27, 31)',
                padding: '20px',
                textAlign: 'center',
                borderRadius: '0px',
                maxWidth: '25%',
                maxHeight: '100%',
                overflow: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgb(204, 204, 220) transparent',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar-track': {
                    background: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgb(204, 204, 220)',
                    borderRadius: '20px',
                    border: '3px solid transparent'
                }
            }}>
                <div style={{ position: 'relative' }}>
                    <img src="/public/img/nois_logo_text_dark.png" alt="Logo" style={{ width: '200px', height: 'auto', marginBottom: '60px' }} />
                    <div style={{
                        position: 'absolute',
                        bottom: '-5px',
                        left: '0',
                        height: '1px',
                        width: '100%',
                        backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgb(145, 145, 145), rgba(255, 255, 255, 0))',
                        marginBottom: '40px'
                    }} />
                </div>
                <h1 style={{ fontWeight: 'bold' }}>Terms of Service</h1>
                <p>By using our website, you agree to the Terms of Service. These terms apply to all visitors, users and others who access or use the Service.</p>
                <h2 style={{ fontWeight: 'bold' }}>Content</h2>
                <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material. You are responsible for the content you post to the service.</p>
                <h2 style={{ fontWeight: 'bold' }}>Changes</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will try to provide at least 30 days' notice prior to any new terms taking effect.</p>
            </div>
        </div>
    );
}

export default TermsOfService;